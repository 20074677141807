import { createSlice, createAction, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "./store";
import { PlayerCreateResponse } from "../types/player";

export interface PlayerEntryState {
    name: string;
    photo: string;
    email: string;
    sessionId: string;
    firstName: string;
    lastName: string;
    marketingOptIn: boolean;

    // Table number is null if the session is a tournament, in this case they are sent to the lobby
    tableNumber: string | null;
    createResult: PlayerCreateResponse;
}

const initialState: PlayerEntryState = {
    name: "",
    photo: "",
    email: "",
    firstName: "",
    lastName: "",
    marketingOptIn: false,
    sessionId: "",
    tableNumber: null,
    createResult: {id: "", createSuccess: false, eventType: 0, reason: "", teamName: "", ocheNumber: 0}
}

export const playerEntryStateSlice = createSlice({
    name: "playerEntryStateSlice",
    initialState: initialState,
    reducers: {
        updateName: (state, action: PayloadAction<string>) => { state.name = action.payload },
        updateEmail: (state, action: PayloadAction<string>) => { state.email = action.payload },
        updatePhoto: (state, action: PayloadAction<string>) => { state.photo = action.payload },
        updateFirstName: (state, action: PayloadAction<string>) => { state.firstName = action.payload },
        updateLastName: (state, action: PayloadAction<string>) => { state.lastName = action.payload },
        updateMarketingOptIn: (state, action: PayloadAction<boolean>) => { state.marketingOptIn = action.payload },
        updateSessionId: (state, action: PayloadAction<string>) => { state.sessionId = action.payload },
        updateTableNumber: (state, action: PayloadAction<string|null>) => { state.tableNumber = action.payload },
        updateCreateResult: (state, action: PayloadAction<PlayerCreateResponse>) => { state.createResult = action.payload }
    },
    extraReducers: (builder) => {
        builder.addCase(clearAction, () => initialState)
    }
});

export const {updateName} = playerEntryStateSlice.actions;
export const {updateEmail} = playerEntryStateSlice.actions;
export const {updatePhoto} = playerEntryStateSlice.actions;
export const {updateFirstName} = playerEntryStateSlice.actions;
export const {updateLastName} = playerEntryStateSlice.actions;
export const {updateMarketingOptIn} = playerEntryStateSlice.actions;
export const {updateSessionId} = playerEntryStateSlice.actions;
export const {updateTableNumber} = playerEntryStateSlice.actions;
export const {updateCreateResult} = playerEntryStateSlice.actions;

export const getName = (state: RootState) => state.playerEntry.name;
export const getEmail = (state: RootState) => state.playerEntry.email;
export const getPhoto = (state: RootState) => state.playerEntry.photo;
export const getFirstName = (state: RootState) => state.playerEntry.firstName;
export const getLastName = (state: RootState) => state.playerEntry.lastName;
export const getMarketingOptIn = (state: RootState) => state.playerEntry.marketingOptIn;
export const getSessionId = (state: RootState) => state.playerEntry.sessionId;
export const getTableNumber = (state: RootState) => state.playerEntry.tableNumber;
export const getCreateResult = (state: RootState) => state.playerEntry.createResult;

export const clearAction = createAction ('clear');

export default playerEntryStateSlice.reducer;