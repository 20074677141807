import './loading.css'; 

export const Loading: React.FC = () => {
    return (
    <div className="pageContainer">
        <div className="logoContainer">
            <img src={'./img/fc-logo.svg'} width="90px" alt="Flight Club" />
        </div>
        <div className="loadingContainer">
            <div className="lds-background"></div>
            <div className="lds-background-inner"></div>
            <div className="lds-ring">
               <div></div><div></div><div></div><div></div>
            </div>
            <div className="loadingText">LOADING</div>
        </div>
    </div>
    );
}