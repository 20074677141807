import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import { ValidateLink } from './components/ValidateLink';
import { NameAndEmail } from './components/playerEntryDetails';
import { PlayerPhoto } from './components/playerEntryPhoto';
import { PlayerName } from './components/playerEntryName';
import { Provider } from 'react-redux';
import { store } from './redux/store';
import { PlayerEntryPhotoConfirm } from './components/playerEntryPhotoConfirm';
import { PlayerEntryComplete } from './components/playerEntryComplete';
import { InvalidLink } from './components/InvalidLink';
import { CameraError } from './components/cameraError';
import { SessionCompleted } from './components/SessionCompleted';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const router = createBrowserRouter([
  {
    path: "/",
    element: <ValidateLink />,

    // need to confirm that the session exists by calling the CheckSession endpoint
    // if it does, then we can render the player entry
    // if it doesn't, then we need to render the invalid link page
    loader: async ({ request }) => {
      const url = new URL(request.url);
      const sessionId = url.searchParams.get("s");
      if (sessionId === null) {
        return ("Not Found");
      }
      try {
        const response: Response = await fetch(`/api/CheckSession/${sessionId}`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json"
          }
        }) 

        if (response.ok) {
          const text: string = await response.text();
          return text;
        }
        else {
          return ("Not Found");
        }
      } catch (error) {
        return ("Not Found");
      }
    }
  },
  {
    path: "/Details",
    element: <NameAndEmail />
  },
  {
    path: "/Photo",
    element: <PlayerPhoto />
  },
  {
    path: "/Confirm",
    element: <PlayerEntryPhotoConfirm />
  },
  {
    path: "/Nickname",
    element: <PlayerName />
  },
  {
    path: "/Complete",
    element: <PlayerEntryComplete />
  },
  {
    path: "/InvalidLink",
    element: <InvalidLink />
  },
  {
    path: "/CameraError",
    element: <CameraError />
  },
  {
    path: "/SessionCompleted",
    element: <SessionCompleted />
  }
]

);

root.render(
  <Provider store={store}>
  <React.StrictMode>
     <RouterProvider router={router} />
  </React.StrictMode>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
