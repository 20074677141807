import { useCallback, useEffect, useRef, useState } from "react";
import Webcam from "react-webcam";
import { useAppDispatch } from "../redux/hooks";
import { getSessionId, getTableNumber, updatePhoto, getName } from "../redux/slices";
import { useNavigate } from "react-router";
import { useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { Loading } from './Loading';
import { ProgressBar } from './ProgressBar';

import './playerEntryPhoto.css';

export interface PlayerPhotoProps {
    goBack: () => void;
}

export const PlayerPhoto: React.FC = () => {
    const webcamRef = useRef<Webcam>(null); // create a webcam reference
    const dispatch = useAppDispatch();
    const nav = useNavigate();
    const [isPortait, setIsPortrait] = useState<boolean>(true);

    // BEGIN copied link checking

    const [loading, setLoading] = useState<boolean>(true);
    const [searchParams] = useSearchParams();

    
    // if the redux store is missing a session ID (or the session ID does not match the URL parameter), 
    // then redirect to the start page. Suspect that this is a shared link and this "new" user needs to start at the beginning of the journey
    let sessionId = searchParams.get('s');
    let tableNumber = searchParams.get('t');
    const sessionIdRedux = useSelector(getSessionId);
    const tableNumberRedux = useSelector(getTableNumber);
    const name = useSelector(getName);

    useEffect(() => {
        if (sessionIdRedux === null || tableNumberRedux === null) {
            nav(`/?s=${sessionId}&t=${tableNumber}`);
        }

        if (sessionId !== sessionIdRedux || tableNumber !== tableNumberRedux) {
            nav(`/?s=${sessionId}&t=${tableNumber}`);
        }
        else {
            setLoading(false);
        }
    }, [sessionId, tableNumber, sessionIdRedux, tableNumberRedux, nav]);

    // END of "copied link" checking


    // check the orientation of the device
    // also need to respond to orientation changes

    const checkOrientation = () => {
        switch (window.screen.orientation.type) {
            case "landscape-primary":
            case "landscape-secondary":
                setIsPortrait(false);
                break;
            case "portrait-secondary":
            case "portrait-primary":
                setIsPortrait(true);
                break;
            default:
                console.log("The orientation API isn't supported in this browser :(");
        }
    }

    useEffect(() => {
        try {
            window.screen.orientation.onchange = () => {
                checkOrientation();
            }
        }
        catch (error) {
            console.log(error);
        }
        
    }, []);


    const videoConstraints = {
        width: { min: 640, ideal: 640, max: 768 },
        facingMode: "user",
        aspectRatio: 1.3333333333
    };

    const portraitVideoConstraints = {
        height: { min: 640, ideal: 640, max: 768 },

        facingMode: "user",
        aspectRatio: 0.75
    };

    // create a capture function
    const capture = useCallback(() => {
        if (webcamRef?.current === null) return;
        const imageSrc = webcamRef.current.getScreenshot();

        if (imageSrc === null) return;

        dispatch(updatePhoto(imageSrc));
        nav(`/confirm?s=${sessionId}&t=${tableNumber}`);
    }, [sessionId, tableNumber, webcamRef, nav, dispatch]);


    if (loading) return (<Loading />);

    const onCameraError = () => {
        nav("/cameraerror");
    }

    return (
        <div className="pageContainer">
            <div className="logoContainer">
                <img src={'./img/fc-logo.svg'} width="90px" alt="Flight Club" />
            </div>
            <ProgressBar progress={2} />
            <div className="playerPhotoContainer">
                <div className="videoContainer">
                    <Webcam className="playerPhotoMask" videoConstraints={isPortait ? portraitVideoConstraints : videoConstraints} ref={webcamRef} screenshotFormat={"image/jpeg"} mirrored={true} onUserMediaError={onCameraError} />
                </div>
                <div className="playerPhotoBorder"><img src="./img/photo_border.png" alt="" /></div>
            </div>
            <div className="playerNameBannerCenter">{name}</div>
            <footer className="btn-container">
                <button onClick={capture}>TAKE PHOTO</button>
            </footer>
        </div>);
}