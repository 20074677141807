import { useEffect } from "react";
import { useLoaderData, useNavigate, useSearchParams } from "react-router-dom";
import { useAppDispatch } from "../redux/hooks";
import { updateSessionId, updateTableNumber } from "../redux/slices";

export const ValidateLink: React.FC = () => {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const sessionStatus = useLoaderData();
    let sessionId = searchParams.get('s');
    let tableNumber = searchParams.get('t');
    const dispatch = useAppDispatch();

    useEffect(() => {
        if (sessionId === null) {
            navigate(`/invalidlink?session`);
            return;
        }

        switch (sessionStatus) {
            case "Started":
                dispatch(updateSessionId(sessionId));
                dispatch(updateTableNumber(tableNumber));
                navigate(`/nickname?s=${sessionId}&t=${tableNumber}`);
                break;
            case "Finished":
                navigate(`/sessioncompleted`);
                break;
            case "Ended":
                navigate(`/sessioncompleted`);
                break;
            case "Pending":
                navigate(`/invalidlink?pending`);
                break;
            case "Not Found":
            case null:
            case undefined:
                navigate(`/invalidlink?undefined`);
                break;
        }
    }, [sessionStatus, navigate, sessionId, tableNumber, dispatch]);

    return (<></>);
}