import { SubmitHandler, useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { getSessionId, getTableNumber, updateName } from "../redux/slices";
import { useEffect, useState } from "react";
import { useAppDispatch } from "../redux/hooks";
import { useSearchParams } from "react-router-dom";
import { Loading } from './Loading';
import { ProgressBar } from './ProgressBar';
import './playerEntryName.css';

type FormValues = {
    name: string;
};

export const PlayerName: React.FC = () => {
    const { register, handleSubmit, formState: { isValid } } = useForm<FormValues>();
    const [loading, setLoading] = useState<boolean>(true);
    const [searchParams] = useSearchParams();
    const sessionId = useSelector(getSessionId);
    const tableNumber = useSelector(getTableNumber);

    const dispatch = useAppDispatch();
    const nav = useNavigate();

    // if the redux store is missing a session ID (or the session ID does not match the URL parameter), 
    // then redirect to the start page. Suspect that this is a shared link and this "new" user needs to start at the beginning of the journey
    let sessionIdParam = searchParams.get('s');
    let tableNumberParam = searchParams.get('t');

    useEffect(() => {
        if (sessionId === null || tableNumber === null) {
            nav(`/?s=${sessionIdParam}&t=${tableNumberParam}`);
        }

        if (sessionId !== sessionIdParam || tableNumber !== tableNumberParam) {
            nav(`/?s=${sessionIdParam}&t=${tableNumberParam}`);
        }
        else {
            setLoading(false);
        }
    }, [sessionId, tableNumber, sessionIdParam, tableNumberParam, nav]);

    // END of "copied link" checking

    const onSubmit: SubmitHandler<FormValues> = (data) => {

        setLoading(true);
        dispatch(updateName(data.name))
        nav(`/photo?s=${sessionIdParam}&t=${tableNumberParam}`);
    }

    if (loading) return (<Loading />);

    return (
        <div className="pageContainer">
            <div className="logoContainer">
                <img src={'./img/fc-logo.svg'} width="90px" alt="Flight Club" />
            </div>
            <ProgressBar progress={1} />
            <div className="playerPhotoIcon">
                <img src={'./img/player-icon.svg'} alt="Player Icon" />
            </div>
            <div className="formContainer">
                <form onSubmit={handleSubmit(onSubmit)}>
                    <input type="text" placeholder="Player name" maxLength={10} autoComplete="nickname" {...register("name", { required: true, maxLength: { value: 10, message: "Max 10 characters" } })} /> <br/>
                    <footer className="btn-container">
                        <button type="submit" disabled={!isValid}>ACCEPT</button>
                    </footer>
                </form>
            </div>
        </div>
    );
}